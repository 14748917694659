import { userAxios } from "../index";

const loginUser = async (data) => await userAxios.post('login', data).then(res => res.data)

const loginData = async (data) => await userAxios.post('me', data).then(res => res.data)

const logoutUser = async () => await userAxios.post('logout').then(res => res.data)

const selectFacility = async (data) => await userAxios.post('facility_sesion', data).then(res => res.data)

export default {
    loginUser,
    loginData,
    logoutUser,
    selectFacility
}