var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('index', 'cash_accounts'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
    'menu-item-open': _vm.hasActiveChildren('/account'),
  },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.$can('index', 'cash_accounts'))?_c('router-link',{attrs:{"to":"/cash-account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isActive = ref.isActive;
  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-cogwheel-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Cash Account ")])])])]}}],null,false,868577128)}):_vm._e(),(_vm.$can('index', 'not_closed_cash'))?_c('router-link',{attrs:{"to":"/not-closed-cash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-open-box"}),_c('span',{staticClass:"menu-text"},[_vm._v("Open Box")])])])]}}],null,false,631635993)}):_vm._e(),_c('router-link',{attrs:{"to":"/cash-daily-boxes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Daily Boxes ")])])])]}}],null,false,2063502028)})],1)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon far fa-money-bill-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Cash Account ")]),_c('i',{staticClass:"menu-arrow"})])}]

export { render, staticRenderFns }