var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('index', 'administration'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
    'menu-item-open': _vm.hasActiveChildren('/admin'),
  },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.$can('admindata', 'administration'))?_c('router-link',{attrs:{"to":"/admin-data"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isActive = ref.isActive;
  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-file-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Admin Data ")])])])]}}],null,false,1406322002)}):_vm._e(),(_vm.$can('admindata', 'administration'))?_c('router-link',{attrs:{"to":"/menberships-dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-file-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Admin Menbership ")])])])]}}],null,false,4002242803)}):_vm._e(),(_vm.$can('index', 'users'))?_c('router-link',{attrs:{"to":"/admin/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Users ")])])])]}}],null,false,186421556)}):_vm._e(),(_vm.$can('index', 'facilities'))?_c('router-link',{attrs:{"to":"/admin/facilities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-building"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Facilities ")])])])]}}],null,false,431544551)}):_vm._e(),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
            'menu-item-open': _vm.hasActiveChildren('/admin/appointment'),
          },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(2),(_vm.$can('index', 'medication'))?_c('router-link',{attrs:{"to":"/admin/appointment/packages"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Packages")])])])]}}],null,false,1078974168)}):_vm._e(),(_vm.$can('index', 'medication'))?_c('router-link',{attrs:{"to":"/admin/appointment/medication"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Medications")])])])]}}],null,false,3989459097)}):_vm._e(),(_vm.$can('index', 'campaign'))?_c('router-link',{attrs:{"to":"/admin/appointment/campaign"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("campaign")])])])]}}],null,false,3605410607)}):_vm._e(),(_vm.$can('index', 'membership-parameters'))?_c('router-link',{attrs:{"to":"/admin/appointment/membership-parameters"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Memberships Parameters")])])])]}}],null,false,2997651626)}):_vm._e(),(_vm.$can('index', 'appointment-messages'))?_c('router-link',{attrs:{"to":"/admin/appointment/appointment-messages"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Appointment messages")])])])]}}],null,false,4022553478)}):_vm._e(),(_vm.$can('index', 'allergies'))?_c('router-link',{attrs:{"to":"/admin/appointment/allergies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Allergies")])])])]}}],null,false,3219571455)}):_vm._e(),(_vm.$can('index', 'ailments'))?_c('router-link',{attrs:{"to":"/admin/appointment/ailment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Personal pathological history")])])])]}}],null,false,141316906)}):_vm._e(),(_vm.$can('index', 'tests'))?_c('router-link',{attrs:{"to":"/admin/appointment/test"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Tests")])])])]}}],null,false,792667252)}):_vm._e(),(_vm.$can('index', 'diagnostic_imagings'))?_c('router-link',{attrs:{"to":"/admin/appointment/diagnostic-image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Diagnostic imaging")])])])]}}],null,false,2700512724)}):_vm._e(),(_vm.$can('index', 'category_diagnostic_imagings'))?_c('router-link',{attrs:{"to":"/admin/appointment/diagnostic-imaging-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Diagnostic Imaging Category")])])])]}}],null,false,1856677252)}):_vm._e(),(_vm.$can('index', 'procedures'))?_c('router-link',{attrs:{"to":"/admin/appointment/procedures"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Procedures")])])])]}}],null,false,2616611151)}):_vm._e(),(_vm.$can('index', 'category_lab'))?_c('router-link',{attrs:{"to":"/admin/appointment/category_lab"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Laboratories category")])])])]}}],null,false,2663851332)}):_vm._e(),(_vm.$can('index', 'laboratory'))?_c('router-link',{attrs:{"to":"/admin/appointment/laboratory"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Laboratories")])])])]}}],null,false,3251564948)}):_vm._e(),(_vm.$can('index', 'review_of_system'))?_c('router-link',{attrs:{"to":"/admin/appointment/review-system"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Review System")])])])]}}],null,false,3093301230)}):_vm._e(),(_vm.$can('index', 'cat_review_of_system'))?_c('router-link',{attrs:{"to":"/admin/appointment/review-system-category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Review System Category")])])])]}}],null,false,4077310046)}):_vm._e(),(_vm.$can('index', 'treatments'))?_c('router-link',{attrs:{"to":"/admin/appointment/treatment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Treatments")])])])]}}],null,false,1935221734)}):_vm._e(),(_vm.$can('index', 'diagnoses'))?_c('router-link',{attrs:{"to":"/admin/appointment/diagnoses"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Diagnoses")])])])]}}],null,false,1180044382)}):_vm._e(),(_vm.$can('index', 'consult_prices'))?_c('router-link',{attrs:{"to":"/admin/appointment/consult-price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Consult Price")])])])]}}],null,false,2036823936)}):_vm._e(),(_vm.$can('index', 'type_visits'))?_c('router-link',{attrs:{"to":"/admin/appointment/visit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Type visits")])])])]}}],null,false,1765300651)}):_vm._e()],1)])])],1)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-cogwheel-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Administration ")]),_c('i',{staticClass:"menu-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon far fa-map"}),_c('span',{staticClass:"menu-text"},[_vm._v("Settings Appointment")]),_c('i',{staticClass:"menu-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])}]

export { render, staticRenderFns }