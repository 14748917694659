<template>
  <div>
    <treeselect
      v-model="facility"
      class="w-50 rounded"
      style="background: #fff"
      :options="facilityOptions"
      :clearable="true"
      :disabled="facilityOptions.length === 1"
      :normalizer="normalizer"
      @select="changeFacility"
      :value-format="facilityOptions.length === 1 ? 'object' : 'id'"
    >
    </treeselect>
  </div>
</template>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import axiosU from "@/core/services/api/auth";

export default {
  data() {
    return {
      facilityOptions: [],
      facility: null,
      setTimeoutBuscador: "",
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  mounted() {
    this.facility = JSON.parse(atob(localStorage.getItem("Facility")));
    this.facilityOptions = JSON.parse(
      atob(localStorage.getItem("setFacilityOptions"))
    );
  },
  methods: {
    changeFacility(item) {
      /* this.$pusher.unsubscribe(
        `presence-officeVisit.${
          JSON.parse(atob(localStorage.getItem("Facility"))).id
        }`
      ); */
      const datos = {
        facilities_id: item.id,
      };
      axiosU.selectFacility(datos).then(({ facilities_id }) => {
        this.$variableGlobal.facility = item.id;
        localStorage.setItem("facility", facilities_id);
        localStorage.setItem("Facility", btoa(JSON.stringify(this.facility)));
        /* this.$pusher.subscribe(
          `presence-officeVisit.${
            JSON.parse(atob(localStorage.getItem("Facility"))).id
          }`
        ); */
      });
    },
  },
};
</script>

<style></style>
