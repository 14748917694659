<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <Admin></Admin>
    <Appointment></Appointment>
    <Patient></Patient>
    <OfficeVisit></OfficeVisit>
    <AdminData></AdminData>
    <CashAccount></CashAccount>
  </ul>
</template>

<script>
import Admin from './components/Admin.vue'
import AdminData from './components/AdminData.vue'
import Appointment from './components/Appointment.vue'
import Patient from './components/Patient.vue'
import OfficeVisit from './components/OfficeVisit.vue'
import CashAccount from './components/CashAccount.vue'
export default {
  name: "KTMenu",
  components:{
    Admin,
    Patient,
    Appointment,
    OfficeVisit,
    CashAccount,
    AdminData
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
